import Default from 'gatsby-theme-carbon/src/templates/Default';
import React, {useState, useEffect, useRef} from 'react';
import {graphql} from 'gatsby';
import {Button} from 'carbon-components-react';
import CareerTile from './../components/career-tile';
import {H2} from 'gatsby-theme-carbon/src/components/markdown';
import Shuffle from 'shufflejs'
import {CheckboxChecked24, Checkbox24 } from '@carbon/icons-react';
import { trimSummary, slugify } from './../util'

export default function BrowseCareers({pageContext, location, data}){
	pageContext = {
		...pageContext,
		frontmatter: {
			title: 'Careers in Developmental Disabilities Services'
		}
	};

	const [selectedLevels, setSelectedLevels] = useState([]);
	const [allCareers, setAllCareers] = useState([]);
	const shuffle = useRef(null);
	const shuffleSizer = useRef(null);
	const shuffleContainer = useRef(null);


	let levels = data.allTaxonomyTermExperienceLevels.edges.map(({node}) => {
		let order = 0;
		if(node.name.toLowerCase().includes('intermediate')) order = 10;
		if(node.name.toLowerCase().includes('advanced')) order = 20;
		return {
			name: node.name,
			color: node.field_experience_level_color,
			tid: node.drupal_internal__tid,
			order: order
		}
	})

	useEffect(() => {
		let careers = getCareers(data.allNodeCareer.edges);
		setAllCareers(careers)
		return ()=>{
			if(shuffle.current){
				shuffle.current.destroy()
				shuffle.current = null
			}
		}
	}, [])


	useEffect(() => { 
		if (shuffleContainer.current) { 
			shuffle.current = new Shuffle(shuffleContainer.current, {
				itemSelector: ".career-item",
			})
			shuffle.current.on(Shuffle.EventType.LAYOUT, function (shuffle_evt) {
				shuffle_evt.shuffle.items.forEach(shuffle_item => {
					let shuffle_card = shuffle_item.element.querySelector('.bx--resource-card')
					if(shuffle_card) shuffle_card.removeAttribute('style');
				});
				let active_items = shuffle_evt.shuffle.items.filter((shuffle_item) => shuffle_item.isVisible)
				if(active_items.length) {
					let active_card = active_items[active_items.length - 1].element.querySelector('.bx--resource-card')
					if(active_card) active_card.style.borderRightColor = 'transparent'
				}
			});
		} 
	}, [shuffleContainer.current])
	

	useEffect(()=>{
		if(shuffle.current) {
			if(selectedLevels.length === 0)
				shuffle.current.filter(Shuffle.ALL_ITEMS)
			else
				shuffle.current.filter([...selectedLevels])
		}
	}, [selectedLevels])
	
	const getCareers = (edges) => {
		return edges.map(({node}) => {
			let slug = slugify(node.title);
			let lvl = levels.find((a_level) => node.relationships.field_career_experience_level.drupal_internal__tid === a_level.tid)
			return {
				title: node.title,
				career_description: trimSummary(node.field_career_description.summary),
				url: `/browse-careers/${slug}`,
				career_experience_level: lvl,
				groups: lvl.name,
				nid: node.drupal_internal__nid
			}
		})
	}


	const renderLevelsSelector = () => {
		return levels.sort((a,b)=>a.order - b.order).map(level => {
			let is_inactive = selectedLevels.indexOf(level.name) === -1
			let kind = is_inactive ? 'secondary' : 'primary'
			let icon = is_inactive ?  Checkbox24 : CheckboxChecked24
			return <Button
				key={level.name}
				kind={kind}
				renderIcon={icon}
				onClick={()=>{
					if(selectedLevels.indexOf(level.name) === -1)
						setSelectedLevels([...selectedLevels, level.name])
					else
						setSelectedLevels(selectedLevels.filter(lvl => lvl !== level.name))
				}}
			>
				{level.name}
			</Button>
		})
	}

	const renderCareers = () => {
		if(allCareers.length === 0) return null

		return (
			<div
				ref={shuffleContainer}
				className="resource-card-group careers-card-group careers-card-to-shuffle"
				style={{
					width: "100%",
					position: "relative",
					overflow: "hidden"
				}}
			>
				{allCareers.map((career, c_index) => (
					<div
						key={career.nid}
						className="career-item"
						data-groups={JSON.stringify([career.groups])}
					>
						<CareerTile career={career} isLast={c_index == (allCareers.length - 1)}  />
					</div>
				))}
				<div ref={shuffleSizer} style={{ width: 180 }} />
			</div>
		)
	}

	return (
		<Default pageContext={pageContext} location={location}>
			<H2>What type of Job are you looking for?</H2>
				<span>Experience level:</span>
			<div className="levels-selector">
				{renderLevelsSelector()}
			</div>
			{renderCareers()}
		</Default>
	);
}


export const query = graphql`
{
	allNodeCareer(sort: {fields: title, order: ASC}) {
	  edges {
		node {
		  drupal_internal__nid
		  field_career_description {
			summary
		  }
		  title
		  relationships {
			field_career_experience_level {
			  drupal_internal__tid
			  name
			  field_experience_level_color
			}
		  }
		}
	  }
	}
	allTaxonomyTermExperienceLevels {
	  edges {
		node {
		  drupal_internal__tid
		  name
		  field_experience_level_color
		}
	  }
	}
  }
  
  
`